module.exports = [{
      plugin: require('/Users/nickdrane/coding/blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/Users/nickdrane/coding/blog/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://nickdrane.com"},
    },{
      plugin: require('/Users/nickdrane/coding/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/nickdrane/coding/blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":680,"withWebp":true},
    },{
      plugin: require('/Users/nickdrane/coding/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-107252357-1"},
    },{
      plugin: require('/Users/nickdrane/coding/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
